import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/', redirect: '/user/login' },
    { path: '/main-page', redirect: '/user/login' },
    { path: '/user-info', component: () => import('@/views/userInfo/userInfo.vue'), meta: { title: '个人中心' } },

    // 登录部分
    { path: '/user/login', name: 'userLogin', component: () => import('@/views/login/userLogin.vue'), meta: { title: '登录' } },
    { path: '/admin/login', name: 'adminLogin', component: () => import('@/views/login/adminLogin.vue'), meta: { title: '后台管理系统' } },
    { path: '/auth/admin/login', name: 'authAdminLogin', component: () => import('@/views/login/authAdminLogin.vue'), meta: { title: '后台管理系统' } },

    // SJT
    { path: '/sjt/sjtExamDesc', name: 'sjtExamDesc', component: () => import('@/views/sjt/examDesc.vue'), meta: { title: '' } },
    { path: '/sjt/exam', name: 'sjtExam', component: () => import('@/views/sjt/exam.vue'), meta: { title: '' } },
    { path: '/sjt/result', name: 'sjtResult', component: () => import('@/views/sjt/examResult.vue'), meta: { title: '' } },

    // SST 
    { path: '/sst/sstList', name: 'sstList', component: () => import('@/views/sst/sstList.vue'), meta: { title: '' } },
    { path: '/sst/sstIntro', name: 'sstIntro', component: () => import('@/views/sst/sstIntro.vue'), meta: { title: '' } },
    { path: '/sst/sstExam', name: 'sstExam', component: () => import('@/views/sst/sstExam.vue'), meta: { title: '' } },
    { path: '/sst/sstResult', name: 'sstResult', component: () => import('@/views/sst/sstResult.vue'), meta: { title: '' } },

    // manage 后台管理
    { path: '/manage/projectList', name: 'manageProjectList', component: () => import('@/views/manage/project/manageProjectList.vue'), meta: { title: '项目列表' } },
    { path: '/manage/createProject', name: 'manageCreateProject', component: () => import('@/views/manage/project/manageCreateProject.vue'), meta: { title: '新建项目' } },
    { path: '/manage/updateProject', name: 'updateProject', component: () => import('@/views/manage/project/updateProject.vue'), meta: { title: '修改项目' } },

    // 后台企业部分
    { path: '/manage/enterpriseList', name: 'enterpriseList', component: () => import('@/views/manage/enterprise/enterpriseList.vue'), meta: { title: '企业列表' } },
    { path: '/manage/addEnterprise', name: 'addEnterprise', component: () => import('@/views/manage/enterprise/addEnterprise.vue'), meta: { title: '新建企业' } },
    { path: '/manage/editEnterprise', name: 'editEnterprise', component: () => import('@/views/manage/enterprise/editEnterprise.vue'), meta: { title: '编辑企业' } },

    // 后台人员部分
    { path: '/manage/personList', name: 'personList', component: () => import('@/views/manage/person/personList.vue'), meta: { title: '人员列表' } },
    { path: '/manage/addPerson', name: 'addPerson', component: () => import('@/views/manage/person/addPerson.vue'), meta: { title: '添加人员' } },
    { path: '/manage/editPerson', name: 'editPerson', component: () => import('@/views/manage/person/editPerson.vue'), meta: { title: '编辑人员' } },
    { path: '/manage/patchAddPerson', name: 'patchAddPerson', component: () => import('@/views/manage/person/patchAddPerson.vue'), meta: { title: '添加人员' } },
    { path: '/manage/patchUploadPerson', name: 'patchUploadPerson', component: () => import('@/views/manage/person/patchUploadPerson.vue'), meta: { title: '添加人员' } },

    // 操作说明
    { path: '/user/sstUserIntro', name: 'sstUserIntro', component: () => import('@/views/userInfo/sstUserIntro.vue'), meta: { title: '操作说明' } },
    { path: '/user/sjtUserIntro', name: 'sjtUserIntro', component: () => import('@/views/userInfo/sjtUserIntro.vue'), meta: { title: '操作说明' } },

    // 错误处理，项目已被删除的路由
    { path: '/project/notFound', name: 'projectNotFound', component: () => import('@/views/manage/project/projectNotFound.vue'), meta: { title: '项目不存在' } },
    { path: '/project/noRight', name: 'noRight', component: () => import('@/views/manage/project/projectNoRight.vue'), meta: { title: '暂无权限' } },
    // 链接失效的路由
    { path: '/project/urlNotInUse', name: 'urlNotInUse', component: () => import('@/views/login/urlNotInUse.vue'), meta: { title: '链接已失效' } },
  ],
})


router.beforeEach((to, from, next) => {
  // 路由发生变化修改页面title
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  next()
})

// 解决报错
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// 针对 push 方法
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// 针对 replace 方法
VueRouter.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

export default router
