let user = {
    namespaced: true,
    state: function () {
        return {
            count: 0
        }
    },
    mutations: {
        SET_COUNT: function (state) {
            return state.count++
        }
    }
}

export default user