/*
 * @Author: lishuai lish@zhiding.com.cn
 * @Date: 2022-11-10 18:17:34
 * @LastEditors: lishuai lish@zhiding.com.cn
 * @LastEditTime: 2023-05-11 15:59:04
 * @FilePath: \sst-sjt-online-fe\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store"

// rem 布局
import 'amfe-flexible'

// 导入样式文件
// icon-park 的样式文件
import '@icon-park/vue/styles/index.css'

// 全量引入 vant 框架，作为主框架，直接使用全量组件，不再每个单 vue 文件单独引入组件
import Vant from 'vant';
import 'vant/lib/index.css';

// 导入轮播图的库
// import 'swiper/css/swiper.css'
import 'swiper/swiper-bundle.css'

// 导入 font-awesome css 库，用于sst 选择正确的动画
import "font-awesome/css/font-awesome.min.css"

import { Toast } from 'vant';
Vue.use(Toast);


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);


import VueWechatTitle from 'vue-wechat-title'
Vue.use(VueWechatTitle)

Vue.use(Vant);

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
