import Vue from "vue";
import Vuex from "vuex";
import user from "./user";

Vue.use(Vuex)

let store = new Vuex.Store({
    state: () => {
        return {
            rootCount: 0
        }
    },
    mutations:{
        INCRE_ROOT_COUNT: function (state) {
            return state.rootCount++
        }
    },
    modules: {
        user: user,
    }
})

export default store